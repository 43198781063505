import Text from 'react-components/src/components/text';

const TitleWidget = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const client = searchParams.get('client');

  const title = (() => {
    if (!client) return 'Benepass';

    if (client.includes('employee')) return 'Employee Web App';
    if (client.includes('mobile')) return 'Employee Mobile App';
    if (client.includes('admin')) return 'Admin Dashboard';
    if (client.includes('ops')) return 'Ops Dashboard';
    if (client.includes('claims')) return 'Claims Dashboard';

    return 'Benepass';
  })();

  if (!title) return null;

  return (
    <Text type="display-4" className="text-indigo-100">
      {title}
    </Text>
  );
};

export default TitleWidget;
