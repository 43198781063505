import { useCallback, useState, useMemo } from 'react';

import Input from 'react-components/src/components/textfield';
import Text from 'react-components/src/components/text';
import Button from 'react-components/src/components/button';
import axios from 'axios';

import { Mail as MailIcon } from '@benepass/icons';
import { debounce } from 'utils/src/core-utils';

// @ts-expect-error javascript module
import config from '../config';
import TitleWidget from '../widgets/app-title.widget';

const mailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+([#]{3})?$/;

export type Connection = {
  name: string;
  descriptor: 'google-oauth2' | string;
  is_default: boolean;
};

export type GroupedConnections = {
  default: Connection[];
  others: Connection[];
};

const GoogleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_386_7762)">
      <path
        d="M20 10.2297C20 9.54995 19.9438 8.8665 19.8238 8.19775H10.2006V12.0486H15.7113C15.4827 13.2905 14.7479 14.3892 13.672 15.0873V17.586H16.9597C18.8903 15.8443 20 13.2722 20 10.2297Z"
        fill="#4285F4"
      />
      <path
        d="M10.2005 19.9998C12.9522 19.9998 15.2727 19.1142 16.9634 17.5857L13.6757 15.0871C12.761 15.697 11.5801 16.0424 10.2043 16.0424C7.54261 16.0424 5.28581 14.2824 4.47606 11.916H1.08337V14.4918C2.81533 17.8686 6.34298 19.9998 10.2005 19.9998V19.9998Z"
        fill="#34A853"
      />
      <path
        d="M4.47227 11.9163C4.04491 10.6743 4.04491 9.32947 4.47227 8.0875V5.51172H1.08333C-0.363715 8.33737 -0.363715 11.6664 1.08333 14.4921L4.47227 11.9163V11.9163Z"
        fill="#FBBC04"
      />
      <path
        d="M10.2005 3.95756C11.6551 3.93552 13.0609 4.47198 14.1143 5.45674L17.0271 2.60169C15.1827 0.904099 12.7347 -0.0292099 10.2005 0.000185607C6.34298 0.000185607 2.81533 2.13136 1.08337 5.51185L4.47232 8.08764C5.27831 5.71762 7.53886 3.95756 10.2005 3.95756V3.95756Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_386_7762">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EmailStep = ({
  onContinue,
  errorMessage,
  initialValue,
}: {
  onContinue: (args: { email: string; connection: string; connections: GroupedConnections }) => void;
  errorMessage: React.ReactNode;
  initialValue?: {
    email?: string;
    connections?: GroupedConnections;
  };
}) => {
  const [value, setValue] = useState(initialValue?.email ?? '');

  const [state, setState] = useState<{
    status: 'idle' | 'loading' | 'success' | 'error';
    data: GroupedConnections | null;
  }>({
    status: initialValue?.connections ? 'success' : 'idle',
    data: initialValue?.connections ?? null,
  });

  const requestEmail = useCallback(async (newValue: string) => {
    if (newValue && newValue.match(mailPattern)) {
      try {
        setState({ status: 'loading', data: null });

        const response = await axios.post<Connection[]>(config.benepassRealm, { email: newValue });
        const connections = response.data ?? [];

        const groupedConnections = connections.reduce(
          (acc, current) => ({
            ...acc,
            [current.is_default ? 'default' : 'others']: [...acc[current.is_default ? 'default' : 'others'], current],
          }),
          { default: [], others: [] }
        );

        setState({ status: 'success', data: groupedConnections });
      } catch {
        setState({ status: 'error', data: null });
      }
    } else {
      setState({ status: 'idle', data: null });
    }
  }, []);

  const requestEmailDebounced = useMemo(() => debounce(requestEmail, 200), [requestEmail]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      if (state.status === 'idle' && newValue.match(mailPattern)) {
        setState({ status: 'loading', data: null });
      }
      setValue(newValue);
      requestEmailDebounced(newValue);
    },
    [requestEmailDebounced, state.status]
  );

  return (
    <>
      <div className="flex flex-col gap-1 items-center pt-8">
        <div className="flex flex-col items-center gap-1">
          <Text type="display-4" className="text-grayscale-80">
            Welcome to
          </Text>

          <TitleWidget />
        </div>

        <Text type="body" size="xl" className="text-grayscale-64">
          Please sign in using your email
        </Text>
      </div>

      <div className="px-4 md:px-10 lg:px-20 flex flex-col gap-3">
        <Input
          type="email"
          autoCapitalize="none"
          value={value}
          onChange={handleChange}
          name="email"
          placeholder="Email address"
          className="w-full"
          leftIcon={<MailIcon />}
        />

        {state.status === 'loading' && (
          <div className="w-full flex flex-col gap-3">
            {Array.from({ length: 3 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="h-[50px] bg-grayscale-24 rounded-lg animate-pulse" />
            ))}
          </div>
        )}

        {state.status === 'success' && !state.data?.default.length && !state.data?.others.length && (
          <Button
            variant="secondary"
            className="flex flex-row gap-3 items-center"
            onClick={() => {
              onContinue({
                email: value,
                connection: '',
                connections: (state.data as never) || {
                  default: [{ descriptor: '', name: 'email code', is_default: true }],
                  others: [],
                },
              });
            }}
          >
            Log in with email code
          </Button>
        )}

        {state.status === 'error' || errorMessage ? (
          <Text type="body" size="sm" className="text-red-100">
            {errorMessage}
          </Text>
        ) : null}

        {['success', 'idle'].includes(state.status) && state.data?.default ? (
          <div className="flex flex-col gap-2">
            {state.data.default.map((item) => (
              <Button
                variant="secondary"
                key={item.name}
                className="flex flex-row gap-3 items-center"
                onClick={() => {
                  onContinue({ email: value, connection: item.descriptor, connections: state.data as never });
                }}
              >
                {(() => {
                  switch (item.descriptor) {
                    case 'google-oauth2':
                      return <GoogleIcon />;
                    default:
                      return null;
                  }
                })()}

                {`Log in with ${item.name}`}
              </Button>
            ))}
          </div>
        ) : null}

        {['success', 'idle'].includes(state.status) && state.data?.default?.length && state.data?.others?.length ? (
          <div className="py-1 flex items-center gap-4">
            <div className="h-[1px] bg-grayscale-8 flex-1" />
            <Text type="header-5" className="text-grayscale-64">
              or
            </Text>
            <div className="h-[1px] bg-grayscale-8 flex-1" />
          </div>
        ) : null}

        {['success', 'idle'].includes(state.status) && state.data?.others ? (
          <div className="flex flex-col gap-3">
            {state.data.others.map((item) => (
              <Button
                variant="secondary"
                key={item.name}
                className="flex flex-row gap-3 items-center"
                onClick={() =>
                  onContinue({ email: value, connection: item.descriptor, connections: state.data as never })
                }
              >
                {(() => {
                  switch (item.descriptor) {
                    case 'google-oauth2':
                      return <GoogleIcon />;
                    default:
                      return null;
                  }
                })()}

                {`Log in with ${item.name}`}
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default EmailStep;
