import {
  User as UserIcon,
  Sparkle as SparkleIcon,
  Phone as PhoneIcon,
  Graph as GraphIcon,
  Merchant as MerchantIcon,
} from '@benepass/icons';
import { purple } from '@benepass/colors';

const AppIcon = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const client = searchParams.get('client');

  const Icon = (() => {
    if (!client) return UserIcon;

    if (client.includes('employee')) return UserIcon;
    if (client.includes('mobile')) return PhoneIcon;
    if (client.includes('admin')) return GraphIcon;
    if (client.includes('ops')) return SparkleIcon;
    if (client.includes('claims')) return MerchantIcon;

    return UserIcon;
  })();

  if (!Icon) return null;

  return (
    <div className="absolute rounded-full bg-grayscale-0 -top-6 self-center flex gap-3 items-center">
      <div className="bg-purple-10 rounded-full p-2">
        <Icon size={30} color={purple['100']} />
      </div>
    </div>
  );
};

export default AppIcon;
