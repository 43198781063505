import React from 'react';
import 'react-notifications-component/dist/theme.css';

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

import 'react-components/src/utils/styles';
import './css/App.css';

import App from './app';
import current from './config';
import packageJSON from '../package.json';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: current.sentry.dsn,
  release: `sso@${packageJSON.version}`,
  environment: current.sentry.environment,
  attachStacktrace: true,
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
    new Sentry.Replay({
      blockAllMedia: false,
    }),
  ],
  denyUrls: ['localhost'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Sentry.ErrorBoundary fallback="An error has occured">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
