/* eslint-disable */
import { ArrowLeft } from '@benepass/icons';
import { useState, useCallback } from 'react';
import Input from 'react-components/src/components/textfield';
import Button from 'react-components/src/components/button';
import Text from 'react-components/src/components/text';

const PasswordForm = ({ email, loginWithPassword, onBack, errorMessage = null }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await loginWithPassword(email, password);
    setLoading(false);
  }, [email, password, loginWithPassword]);

  return (
    <div className="flex flex-col gap-10 items-center px-10 w-full relative">
      <div className="absolute top-0 left-4">
        <Button onClick={onBack} variant="ghost" className="underline">
          <ArrowLeft />
        </Button>
      </div>

      <div>
        <div className="flex flex-col gap-1 items-center pt-8">
          <Text type="display-4" className="text-grayscale-80">
            Enter the password{email ? ' for' : ''}
          </Text>

          {email ? (
            <Text type="display-4" className="text-indigo-100">
              {email}
            </Text>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col self-center gap-6 w-full">
        <Input
          required
          placeholder="Enter your password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
        />
        <Button disabled={password.length < 5 || loading} onClick={handleSubmit}>
          Log in
        </Button>

        {errorMessage}
      </div>
    </div>
  );
};

export default PasswordForm;
